import TextField from "@material-ui/core/TextField";
import React, { useState, useEffect, useRef, StyleSheet } from 'react';
import { Button, Box,
    Typography,
    IconButton,
    InputAdornment 
} from "@material-ui/core";
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { getUserInfo } from 'src/services/tokens';
import Swal from 'sweetalert2';
import axios from 'axios';
import { baseUrl } from '../services/constants';
import { useNavigate } from 'react-router-dom';
import { alpha, styled } from '@material-ui/core/styles';

const RootStyle = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'normal'
  });

export default function ChangePassword(){

    const navigate = useNavigate();

    const [newPass, setNewPass] = useState({});
    const [valueAlert, setValueAlert] = useState({});
    const [disbleButon, setDisableButon] = useState(false);
    
    const inputRefOld = useRef();
    const inputRefNew = useRef();
    const inputRefNew2 = useRef();
    const cargandoP = useRef();

    const [helperErrorViewPass, setHelperError] = useState({
        old: {
            error: false, helper: "", view: false
        }, new: {
            error: false, helper: "", view: false
        }, new2: {
            error: false, helper: "", view: false
        }
    });     

    useEffect(() => { 

        console.log("helperErrorViewPass => ", helperErrorViewPass) 

    },[helperErrorViewPass])    
    
    useEffect(async () => {

        if(typeof newPass.password === 'string'){
            console.log("newPass => ", newPass)            

            let tipoAlert = {}, txtMssg;
            
            axios.post(`${baseUrl}/auth/change-password`, newPass)
            .catch(function (error) {
                if (error.response) {                    
                    switch(error.response.data.error.details[0]){
                        case "Password attempts exceeded":
                        case "Attempt limit exceeded, please try after some time.":
                            txtMssg = "Intentos de contraseña excedidos, espere 15 minutos";
                            break;
                        case "Incorrect username or password.":
                            txtMssg = "Contraseña Incorrecta";
                            break;
                        case "Password did not conform with policy: Password not long enough":
                            txtMssg = "Longitud de contraseña no permitida";
                            break;
                        default:
                            txtMssg = (error.response.data.error.details[0])
                                ? error.response.data.error.details[0]
                                : "Error";
                            break;
                    }
                    tipoAlert = {
                        title: 'Error!',
                        text: txtMssg,
                        icon: 'error',
                        confirmButtonText: 'Aceptar'
                    }
                    console.log("entra al error", tipoAlert);
                    setValueAlert(tipoAlert); 
                }
            })
            .then((data) => {
                if(data){                    
                    tipoAlert = {
                        title: '¡Bien!',
                        text: "¡La contraseña se cambió exitosamente!",
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 4000
                    }
                    console.log("entra al then", tipoAlert);
                    setValueAlert(tipoAlert); 
                    
                }
            })
            
            setNewPass({});
        }
        
    },[newPass])

    useEffect(() => {
        if(valueAlert.icon == 'success' || valueAlert.icon == 'error'){
            setDisableButon(false);
            cargandoP.current.style.display = 'none';
            if(valueAlert.icon == 'success'){
                Swal.fire(valueAlert).then(() => {
                    localStorage.clear();    
                    navigate('/login', { replace: true });
                })
            }else{
                Swal.fire(valueAlert);
            } 
        }               
    }, [valueAlert]);    

    const ValidarPass = () => {

        setDisableButon(true);
        cargandoP.current.style.display = 'block';

        const TxtOldPass = inputRefOld.current.value;
        const TxtNewPass = inputRefNew.current.value;
        const TxtNewPass2 = inputRefNew2.current.value;
        let swError = false;

        let helperErrorViewPassTmp = {
            old: {
                error: false, helper: "", view: helperErrorViewPass.old.view
            }, new: {
                error: false, helper: "", view: helperErrorViewPass.new.view
            }, new2: {
                error: false, helper: "", view: helperErrorViewPass.new2.view
            }
        };

        const validPass = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~+=`])\S{8,20}$/);
        const txtErrorPolicy = "Política contraseña incorrecta";
        const txtErrorVacio = "No puede estar vacío";
        const txtErrorNoIguales = "Deben ser iguales las contraseñas";

        if(TxtNewPass!=TxtNewPass2){
            helperErrorViewPassTmp.new.error = true;
            helperErrorViewPassTmp.new2.error = true;
            helperErrorViewPassTmp.new.helper = txtErrorNoIguales;
            helperErrorViewPassTmp.new2.helper = txtErrorNoIguales;
            swError = true;
        }

        if(TxtOldPass == "" || !validPass.test(TxtOldPass)){
            helperErrorViewPassTmp.old.error = true;
            helperErrorViewPassTmp.old.helper = (!validPass.test(TxtOldPass)) 
                ? txtErrorPolicy : txtErrorVacio;
            swError = true;
        }

        if(TxtNewPass == "" || !validPass.test(TxtNewPass)){
            helperErrorViewPassTmp.new.error = true;
            helperErrorViewPassTmp.new.helper = (!validPass.test(TxtNewPass)) 
                ? txtErrorPolicy : txtErrorVacio;
            swError = true;
        } 
        
        if(TxtNewPass2 == "" || !validPass.test(TxtNewPass2)){
            helperErrorViewPassTmp.new2.error = true;
            helperErrorViewPassTmp.new2.helper = (!validPass.test(TxtNewPass2)) 
                ? txtErrorPolicy : txtErrorVacio;
            swError = true;
        }        

        if(swError){
            setHelperError(helperErrorViewPassTmp);
            setDisableButon(false);
            cargandoP.current.style.display = 'none';
        }else{

            const user = getUserInfo();

            console.log("user => ", user)

            setNewPass({
                email: user['cognito:username'],
                password: TxtOldPass,
                new_password: TxtNewPass,
                confirm_password: TxtNewPass2
            })            
        }
        
    }

    const resetErrores = () => {
        setHelperError({
            old: {
                error: false, helper: "", view: helperErrorViewPass.old.view
            }, new: {
                error: false, helper: "", view: helperErrorViewPass.new.view
            }, new2: {
                error: false, helper: "", view: helperErrorViewPass.new2.view
            }
        });
    }

    const handleShowPassword = (tipo) => {
        if(tipo=='old'){           
            setHelperError(prevState => ({
                ...prevState,
                old: {
                    error: helperErrorViewPass.old.error,
                    view: !helperErrorViewPass.old.view,
                    helper: helperErrorViewPass.old.helper
                }
            }))      
        }else if(tipo == 'new'){
            setHelperError(prevState => ({
                ...prevState,
                new: {
                    error: helperErrorViewPass.new.error,
                    view: !helperErrorViewPass.new.view,
                    helper: helperErrorViewPass.new.helper
                }
            }))  
        }else if(tipo == 'new2'){
            setHelperError(prevState => ({
                ...prevState,
                new2: {
                    error: helperErrorViewPass.new2.error,
                    view: !helperErrorViewPass.new2.view,
                    helper: helperErrorViewPass.new2.helper
                }
            })) 
        }
    };    

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '50ch' },
            }}
            noValidate            
        >
            <Typography variant="h4" sx={{ m: 1}}>Cambiar Contraseña</Typography>
            <Box>              
                <TextField
                    error={helperErrorViewPass.old.error}
                    id="id-pass-old"
                    label="Contraseña Actual:"
                    helperText={helperErrorViewPass.old.helper}
                    inputRef={inputRefOld}
                    onChange={() => resetErrores()}
                    type={helperErrorViewPass.old.view ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => handleShowPassword('old')} edge="end">
                                <Icon 
                                    icon={helperErrorViewPass.old.view 
                                        ? eyeFill 
                                        : eyeOffFill}
                                />
                            </IconButton>
                        </InputAdornment>
                        )
                    }}
                />
            </Box> 
            <Box>              
                <TextField
                    error={helperErrorViewPass.new.error}
                    id="id-pass-new"
                    label="Nueva Contraseña:"
                    helperText={helperErrorViewPass.new.helper}
                    inputRef={inputRefNew}
                    onChange={() => resetErrores()}
                    type={helperErrorViewPass.new.view ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => handleShowPassword('new')} edge="end">
                                <Icon 
                                    icon={helperErrorViewPass.new.view 
                                        ? eyeFill 
                                        : eyeOffFill}
                                />
                            </IconButton>
                        </InputAdornment>
                        )
                    }}
                />
            </Box>
            <Box autoComplete="off">              
                <TextField
                    error={helperErrorViewPass.new2.error}
                    id="id-pass-new2"
                    label="Repetir Nueva Contraseña:"
                    helperText={helperErrorViewPass.new2.helper}
                    inputRef={inputRefNew2}
                    onChange={() => resetErrores()}
                    type={helperErrorViewPass.new2.view ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => handleShowPassword('new2')} edge="end">
                                <Icon 
                                    icon={helperErrorViewPass.new2.view 
                                        ? eyeFill 
                                        : eyeOffFill}
                                />
                            </IconButton>
                        </InputAdornment>
                        )                        
                    }}
                />            
            </Box>
            <Box sx={{m:1, paddingLeft:'15px'}} style={{fontSize:'13px'}}>
                <p style={{fontWeight:600}}>Requisitos Contraseña:</p>
                <ul style={{paddingLeft:'15px'}}>
                    <li>
                        De <strong>8 a 20 dígitos</strong>, 
                        mínimo <strong>una minúscula</strong>, 
                        mínimo <strong>una mayúscula</strong>, 
                        mínimo <strong>un número</strong>, 
                        y mínimo <strong>un carácter especial</strong>.
                    </li>  
                </ul>   
            </Box>                    
            <RootStyle>
                <Button                    
                    variant="contained"
                    color="primary"
                    sx={{ m: 1}}
                    onClick={() => ValidarPass()}
                    disabled={disbleButon}
                >
                    Actualizar Contraseña
                </Button>
                <p ref={cargandoP} style={{display:'none',color: '#007B55',marginLeft:'8px'}}>Cargando...</p>
            </RootStyle>                   
        </Box>        
    );
}
