import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Link,  Drawer, Typography, Avatar, Stack, Button, Tooltip } from '@material-ui/core';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
//
import sidebarConfig from './SidebarConfig';
import account from '../../_mocks_/account';
import { getUserInfo } from 'src/services/tokens';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200]
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const user = getUserInfo();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
          <Logo />
        </Box>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar src={account.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Tooltip title={!!user ? user['cognito:username'] : ''}>
                <Typography variant="subtitle2" sx={{ 
                  color: 'text.primary',
                  textOverflow: 'ellipsis',
                  whiteSpace: "nowrap",
                  overflow: 'hidden',
                  maxWidth: "156px" 
                }}>
                  {!!user ? user['cognito:username'] : ''}
                </Typography>
              </Tooltip>
              <Tooltip title={!!user ? (user['cognito:groups'][0]).toUpperCase() : ''}>
                <Typography variant="body2" sx={{ 
                  color: 'text.secondary',
                  fontSize: '12px',
                  textOverflow: 'ellipsis',
                  whiteSpace: "nowrap",
                  overflow: 'hidden',
                  maxWidth: "156px" 
                }}>
                  {!!user ? (user['cognito:groups'][0]).toUpperCase() : ''}
                </Typography>
              </Tooltip>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <Stack sx={{
        height: '100%'
      }} 
      direction="column" 
      justifyContent="space-between">
        <NavSection navConfig={sidebarConfig} />
        <Box
          sx={{
            pb: 2,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
            <Button
              style={{ margin: '0 10px 20px 0' }}
              variant="outlined"
              color="info"
              startIcon={<PictureAsPdfIcon />}
              onClick={() => {
                window.open('/static/manual.pdf', '_blank');
              }}
            >
              Descargar Manual
            </Button>
        </Box>
      </Stack>
      
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
