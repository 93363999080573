import { useEffect, useState, useMemo } from 'react';
import { parse as QueryParse, stringify as QueryStringify } from 'query-string';
import { 
    Box,
    Button,
    Typography,
    Stack,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    Modal,
    Backdrop,
    CircularProgress,
    Autocomplete,
    Select,
    MenuItem,
    alpha,
    Snackbar,
    Alert,
    AlertProps
} from '@material-ui/core';
import moment from 'moment';
import Label from '../../components/Label';
import AddIcon from '@material-ui/icons/Add';
import ReplayIcon from '@material-ui/icons/Replay';
import InfiniteScroll from 'react-infinite-scroll-component';
import MobileDateTimePicker from '@material-ui/lab/MobileDateTimePicker';
import LoadingButton from '@material-ui/lab/LoadingButton';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterMoment from '@material-ui/lab/AdapterMoment';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import {createReportStats, getReportStats} from '../../services/reports/stats.service';
import {getDevices} from '../../services/auth.service';
import { getToken, getAccessToken } from '../../services/tokens';

function NewReport(props){

    const {
        afterSave,
        device
    } = props;
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [type, setType] = useState('csv');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const availableForCreate = useMemo(() => {
        return fromDate && toDate && moment(fromDate).isBefore(moment(toDate)) && type
    }, [fromDate, toDate]);

    async function onSave(){
        try{
            if(!navigator.onLine){
                setError('No hay acceso a internet');
                return;
            }
            setLoading(true);
            await createReportStats({
                id: device,
                startDate: fromDate?.format('YYYY-MM-DD HH:mm:ss'),
                endDate: toDate?.format('YYYY-MM-DD HH:mm:ss'),
                type
            });
            setError('');
            afterSave();
        }catch(e){
            setError('Error interno, intentalo nuevamente');
        }finally{
            setLoading(false);
        }
    }

    return (
        <>
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={!!error}
            autoHideDuration={3000}
            onClose={() => {
                setError('')
            }}
        >
            <Alert severity="error" sx={{ width: '100%' }}>
                {error}
            </Alert>
        </Snackbar>
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: {
                xs: 320,
                sm: 600
            },
            bgcolor: 'background.paper',
            boxShadow: 2,
            p: 4,
        }}>
            <Stack spacing={2}>
                <Typography variant="h4">Nuevo Reporte</Typography>
                <Stack spacing={3}>
                    <Stack direction={{
                        xs: 'column',
                        sm: 'row'
                    }} spacing={3} alignItems="center" justifyContent="start">
                        <Stack spacing={1} sx={{
                            flex: 1
                        }}>
                            <Typography variant="subtitle2">Desde: </Typography>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <MobileDateTimePicker
                                    renderInput={(props) => <TextField placeholder='Seleccionar fecha' variant="outlined" {...props} />}
                                    value={fromDate}
                                    onChange={(newValue) => {
                                        setFromDate(newValue);
                                    }}
                                    inputFormat={'YYYY-MM-DD HH:mm:ss'}
                                    views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                                    cancelText='Cancelar'
                                    okText='Aceptar'
                                    toolbarTitle='Seleccionar fecha'
                                    toolbarFormat='HH:mm:ss'
                                />
                            </LocalizationProvider>
                        </Stack>
                        <Stack spacing={1} sx={{
                            flex: 1
                        }}>
                            <Typography variant="subtitle2">Hasta: </Typography>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <MobileDateTimePicker
                                    renderInput={(props) => <TextField placeholder='Seleccionar fecha' variant="outlined" {...props} />}
                                    value={toDate}
                                    onChange={(newValue) => {
                                        setToDate(newValue);
                                    }}
                                    inputFormat={'YYYY-MM-DD HH:mm:ss'}
                                    views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                                    cancelText='Cancelar'
                                    okText='Aceptar'
                                    toolbarTitle='Seleccionar fecha'
                                    toolbarFormat='HH:mm:ss'
                                />
                            </LocalizationProvider>
                        </Stack>
                    </Stack>
                    <Stack direction='row' spacing={1} alignItems='center'>
                        <Box>Tipo:</Box>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={type}
                            onChange={(val) => {
                                setType(val.target.value);
                            }}
                        >
                            <MenuItem value={'csv'}>CSV</MenuItem>
                            <MenuItem value={'xls'}>XLS</MenuItem>
                        </Select>
                    </Stack>
                </Stack>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={onSave}
                    disabled={!availableForCreate}
                    loading={loading}
                >
                    Registrar
                </LoadingButton>
            </Stack>
        </Box>
        </>
    );
}

export default function ReportStatsPage(props) {
    const [{
        nextKey,
        order,
        activateSearch,
        nextIteration,
    }, setStates] = useState({
        nextKey: null,
        order: 'desc',
        activateSearch: false,
        nextIteration: true,
    });
    const [devices, setDevices] = useState([]);
    const [items, setItems] = useState([]);
    const [selectedId, setSelectedId] = useState('');
    const [modalState, setModalState] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingDevices, setLoadingDevices] = useState(false);
    const [error, setError] = useState('');

    const queryToSend = useMemo(() => {
        if(!selectedId) return '';
        const [imei, a, st, fabrica] = selectedId.split('#');
        return QueryStringify({
            imei,
            a,
            st,
            fabrica,
            order,
            $next: nextKey,
            $limit: 25
        }, {
            skipNull: true
        });
    }, [selectedId, order, nextKey]);

    useEffect(async () => {
        try{
            const token = getToken();
            setLoadingDevices(true);
            const devices = await getDevices(token);
            const extract = devices.map(({device}) => {
                return {
                    id: device.deviceId,
                    grupo: device.grupo,
                    descripcion: device.descripcion
                }
            });
            setDevices(extract);
            setLoadingDevices(false);
            setError('');
        }catch(e){
            setError('Error interno, intentalo nuevamente');
        }finally{
            setLoadingDevices(false);
        }
    }, []);

    useEffect(async () => {
        if(!selectedId) return;
        try{
            setLoading(true);
            const {data, next} = await fetchData(queryToSend);
            setItems(data);
            setStates({
                nextKey: next,
                order,
                nextIteration: false,
                activateSearch: false,
            });
            setError('');
        }catch(e){
            setError('Error interno, intentalo nuevamente');
        }finally{
            setLoading(false);
        }
    }, [selectedId]);

    useEffect(async () => {
        if(!activateSearch) return;
        try{
            const {data, next} = await fetchData(queryToSend);
            setItems(data);
            setStates({
                nextKey: next,
                order,
                nextIteration: false,
                activateSearch: false,
            });
            setError('');
        }catch(e){
            setError('Error interno, intentalo nuevamente');
        }finally{
            setLoading(false);
        }
    }, [order, activateSearch]);

    useEffect(async () => {
        if(!nextIteration) return;
        try{
            const {data, next} = await fetchData(queryToSend);
            setItems([
                ...items,
                ...data
            ]);
            setStates({
                nextKey: next,
                order,
                activateSearch: false,
                nextIteration: false,
            });
            setError('');
        }catch(e){
            setError('Error interno, intentalo nuevamente');
        }finally{
            setLoading(false);
        }
    }, [nextIteration]);
    
    async function fetchData(query){
        const {
            data: {
                links,
                payload
            }
        } = await getReportStats(query);
        const nn = links.filter((element) => '$next' in element)[0]?.$next;
        const next = nn ?? null;
        return {
            data: payload.map((element) => ({
                creationTime: element.creationTime,
                intervalStart: element.startDate,
                invervalEnd: element.endDate,
                status: element.status,
                type: element.type,
                url: element.url
            })),
            next
        }
    }

    function readyForSearch(){
        setStates({
            nextKey: null,
            order,
            nextIteration,
            activateSearch: true
        });
        setLoading(true);
    }

    function readyForNextIteration(){
        setStates({
            nextKey,
            order,
            activateSearch,
            nextIteration: true
        });
        setLoading(true);
    }

    function changeOrder(){
        setStates({
            nextKey: null,
            order: order === 'desc' ? 'asc' : 'desc',
            nextIteration,
            activateSearch: true
        });
        setLoading(true);
    }

    return (
        <>
            <Box sx={{width: '100%', px: 2}}>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={!!error} 
                    autoHideDuration={3000}
                    onClose={() => {
                        setError('')
                    }}
                >
                    <Alert severity="error" sx={{ width: '100%' }}>
                        {error}
                    </Alert>
                </Snackbar>
                <Modal
                    open={modalState}
                    onClose={() => {
                        setModalState(false);
                    }}
                    sx={{
                        border: 0,
                        outline: 0
                    }}
                    >
                    <NewReport 
                        device={selectedId}
                        afterSave={() => {
                            setModalState(false);
                            readyForSearch();
                        }}/>
                </Modal>
                <Stack spacing={4}>
                    <Stack spacing={2}>
                        <Typography variant="h4">Crear reporte</Typography>
                        <Stack spacing={1}>
                            <Typography variant="subtitle2">Seleccionar dispositivo</Typography>
                            <Autocomplete
                                id="group-devices"
                                loading={loadingDevices}
                                loadingText={'Cargando ...'}
                                options={devices.sort((a, b) => -b.grupo.localeCompare(a.grupo))}
                                groupBy={(option) => option.grupo}
                                getOptionLabel={(option) => option.descripcion}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} />}
                                onChange={(e, val) => {
                                    setSelectedId(val?.id);
                                    setItems([]);
                                }}
                            />
                        </Stack>
                    </Stack>
                    <Box sx={{
                        position: 'relative'
                    }}>
                        <Backdrop
                            sx={{ 
                                background: alpha('#FFF', 0.85), 
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                                position: 'absolute',
                            }}
                            open={!selectedId}
                        />
                        <Stack spacing={0}>
                            <Box sx={{display: 'flex', flexFlow: 'row', justifyContent: 'flex-end'}}>
                                <Button
                                style={{ margin: '0 10px 20px 0' }}
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    setModalState(true)
                                }}
                                >
                                Crear reporte
                                </Button>
                            </Box>
                        </Stack>
                        <Stack spacing={1}>
                            <Stack spacing={1} direction="row" alignItems="center">
                                <Typography variant="subtitle1">Historial de reportes</Typography>
                                <ReplayIcon
                                    fontSize="small"
                                    onClick={readyForSearch}
                                    sx={{
                                        cursor: 'pointer'
                                    }}
                                />
                            </Stack>
                            <InfiniteScroll
                                dataLength={items.length}
                                next={readyForNextIteration}
                                hasMore={!!nextKey}
                                scrollableTarget={window}
                                scrollThreshold={.9}
                            >
                            <TableContainer sx={{
                                position: 'relative'
                            }}>
                                <Backdrop
                                    sx={{ 
                                        background: alpha('#FFF', 0.85), 
                                        zIndex: (theme) => theme.zIndex.drawer + 1,
                                        position: 'absolute',
                                    }}
                                    open={selectedId && loading}
                                    >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Box 
                                            onClick={changeOrder}
                                            sx={{
                                                cursor: 'pointer',
                                            }}>
                                                <Stack
                                                    direction="row"
                                                    spacing={1}
                                                    alignItems="center"
                                                >
                                                    <Box>Fecha de creaci&oacute;n</Box>
                                                    {
                                                        order === 'desc' ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />
                                                    }
                                                </Stack>
                                            </Box>
                                        </TableCell>
                                        <TableCell>Intervalo</TableCell>
                                        <TableCell>Tipo</TableCell>
                                        <TableCell>Estado</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items.map((row, i) => (
                                            <TableRow
                                                key={i}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                            <TableCell component="th" scope="row">
                                                {row.creationTime}
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row" alignItems="center" spacing={1}>
                                                    <div>{row.intervalStart}</div>
                                                    <Box sx={{fontSize: (theme) => theme.typography.h4.fontSize}}>&rarr;</Box>
                                                    <div>{row.invervalEnd}</div>
                                                </Stack>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.type}
                                            </TableCell>
                                            <TableCell>
                                                <Label
                                                    variant="ghost"
                                                    color={row.status === 'in_progress' ? 'warning' : 'success'}
                                                >{row.status === 'in_progress' ? 'En Progreso' : 'Finalizado'}</Label>
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    row.url ? (
                                                        <a target="_blank" href={row.url}>
                                                            <Box 
                                                                sx={{
                                                                textDecoration: 'underline',
                                                                color: 'secondary.main',
                                                                cursor: 'pointer'
                                                            }}>
                                                            Descargar
                                                            </Box>
                                                        </a>
                                                    ) : null
                                                }
                                            </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                </TableContainer>
                                </InfiniteScroll>
                        </Stack>
                    </Box>
                </Stack>
            </Box>
        </>
    );
}

