import { 
  Box, 
  Grid, 
  Container, 
  Typography,
  Switch,
  Alert,
  Snackbar,
  Button,
  Dialog,
  DialogActions,
  DialogTitle
} from '@material-ui/core';
import { getToken, getRefreshToken } from 'src/services/tokens';
import { isNull } from 'lodash';
import Page from '../../components/Page';
import { useState, useEffect, useRef } from 'react';
import * as moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'moment/locale/es';
import './style.css';
import { 
  ShowDestins, 
  checkTokenExp, 
  createUseCase, 
  updateUseCase, 
  getUseCase, 
  ocultarSpan,
  cancelarTitle
} from './functions';

moment.locale('es');

const FormularioInterno = ({setSnackBar,setSnackBarStatus}) => {

  const [useCase, setUseCase] = useState({});
  const [groupId, setGroupId] = useState('');

  const [openDialog, setOpenDialog] = useState(false);

  const [UsesCaseSMS, setUseCaseSMS] = useState(false);
  const [UsesCaseEmail, setUseCaseEmail] = useState(false);  

  const handleChangeSMS = () => setUseCaseSMS(!UsesCaseSMS);
  const handleChangeEmail = () => setUseCaseEmail(!UsesCaseEmail); 

  const userInfo = JSON.parse(localStorage.getItem('user-info'));

  const valueBorrar = useRef();
  const valueInsertar = useRef(0);
  const valueNotifis = useRef(0);
  const inputUseCaseName = useRef();
  const inputUseCaseDescription = useRef();

  let token = getToken();
  let refreshToken = getRefreshToken();

  if(!isNull(token)) {       
    checkTokenExp(token, refreshToken);      
  }     

  const confirmarBorrado = async () => {    
    const datoBorrar = JSON.parse(valueBorrar.current.value);

    const newDataBorrarDestinatario = (datoBorrar.tipo == 'sms')
      ? useCase.destinationsSms.filter( (e, i) => i !== datoBorrar.posicion)
      : useCase.destinationsEmail.filter( (e, i) => i !== datoBorrar.posicion)
    
    const putdata = await updateUseCase(
      (datoBorrar.tipo == 'sms') 
        ? {destinationsSms: (newDataBorrarDestinatario.length == 0) ? false : newDataBorrarDestinatario}
        : {destinationsEmail: (newDataBorrarDestinatario.length == 0) ? false : newDataBorrarDestinatario},
      groupId, setSnackBar, setSnackBarStatus
    );

    if(putdata.data.status == 'ok'){
      let dataTmp = useCase;
      if(datoBorrar.tipo == 'sms'){
        dataTmp.destinationsSms = newDataBorrarDestinatario;
      }else{
        dataTmp.destinationsEmail = newDataBorrarDestinatario;
      }
      setUseCase(dataTmp);
    }
  }

  useEffect(() => {  
    setGroupId(userInfo['cognito:groups'][0]);     
  },[])

  useEffect(async () => {

    if(groupId !== ''){
      let infoUseCase = await getUseCase(groupId).then((resp) => {
        return resp.data;
      });
  
      if(infoUseCase.status === 'not found'){
        await createUseCase(groupId).then((resp) => {
          return resp.data;
        });
  
        infoUseCase = await getUseCase(groupId).then((data) => {
          return data;
        });
      }   
  
      if(infoUseCase.status === 'ok'){
        setUseCase(infoUseCase.data)
        setUseCaseSMS(infoUseCase.data.NofifiSms)
        setUseCaseEmail(infoUseCase.data.NotifiEmail)
      }else{
        alert("ocurrio un error");  
      }  
    }     

  },[groupId])

  useEffect(async () => {
    if(valueNotifis.current != 0 && typeof valueNotifis.current.value !=='undefined'){            
      const updateNotifis = (valueNotifis.current.value != '')
        ? JSON.parse(valueNotifis.current.value)
        : false;
    
      if(updateNotifis){
        valueNotifis.current.value = '';

        const putdata = await updateUseCase(updateNotifis, groupId, setSnackBar, setSnackBarStatus);

        if(putdata.data.status == 'ok'){
          let dataTmp = useCase;
          dataTmp.NotifiEmail = updateNotifis.NotifiEmail;
          dataTmp.NofifiSms = updateNotifis.NofifiSms;
          setUseCase(dataTmp);
        }
      }
    } 

  }, [UsesCaseEmail,UsesCaseSMS]);

  setInterval(async () => {     

    if(valueInsertar.current != 0 
      && valueInsertar.current != null 
      && valueInsertar.current.value != ''
      && groupId != '' && typeof useCase.useCaseName !== 'undefined'){           

        const updateNotifis = JSON.parse(valueInsertar.current.value);
      
        if(updateNotifis){

          valueInsertar.current.value = '';

          const count_desti = document.getElementsByName(`${updateNotifis.tipo}_-count-dests`)[0].value;

          let destinationsmap = [];
      
          destinationsmap.push((updateNotifis.tipo == 'sms') 
            ? {name: updateNotifis.name, phone: updateNotifis.phone}       
            : {email: updateNotifis.email, name: updateNotifis.name}
          );

          if(count_desti > 0){        
            for(var i = 0; i <= count_desti-1; i++){
              const name_input = document.getElementsByName(`${updateNotifis.tipo}__db-name-${i}`)[0].value;
              const phoneemail_input = document.getElementsByName(`${updateNotifis.tipo}__db-phoneemail-${i}`)[0].value;
              if(updateNotifis.tipo == "sms"){
                destinationsmap.push({name: name_input, phone: phoneemail_input})
              }else{
                destinationsmap.push({name: name_input, email: phoneemail_input})
              }
            }
          } 

          const bodyInsert = (updateNotifis.tipo == 'sms') 
            ? {destinationsSms: destinationsmap}
            : {destinationsEmail: destinationsmap};

          const putdata = await updateUseCase(bodyInsert, groupId, setSnackBar, setSnackBarStatus);

          if(putdata.data.status == 'ok'){
            let dataTmp = useCase;           
            if(updateNotifis.tipo == 'sms'){
              dataTmp.destinationsSms = destinationsmap;
            }else{
              dataTmp.destinationsEmail = destinationsmap;
            } 
            
            setUseCase(dataTmp);
          }

          if(document.getElementById(updateNotifis.tipo) !== null){
            document.getElementById(updateNotifis.tipo).click();
          }
          if(document.getElementById(`_add-${updateNotifis.tipo}`) !== null){
            document.getElementById(`_add-${updateNotifis.tipo}`).setAttribute("class","add-sms");
          }
        
      }
    }    
    
  },250)

  const editarUseCase = async (campo) => {

    document.getElementById(`div-cont-input-${campo}`).setAttribute('class','div-cont-input');
    document.getElementById(`title-${campo}`).setAttribute('class','oculto');

    if(campo == 'useCaseName'){
      inputUseCaseName.current.className = 'oculto visible'; 
      inputUseCaseName.current.value = useCase.useCaseName;
    }else if(campo == 'description'){
      inputUseCaseDescription.current.className = 'oculto visible';
      inputUseCaseDescription.current.value = useCase.description;
    }
  }

  const guardarTitles = async (campo, idInput) => {

    const valueIdInput = idInput.current.value;

    if(valueIdInput.trim() == ''){
      document.getElementById(`span${campo}`).innerText = "Campo obligatorio";
      document.getElementById(`span${campo}`).setAttribute('class', '');
    }else{
      const bodyTitle = (campo == 'useCaseName')
          ? {useCaseName: valueIdInput}
          : {description: valueIdInput};

      const putdata = await updateUseCase(bodyTitle, groupId, setSnackBar, setSnackBarStatus);

      if(putdata.data.status == 'ok'){
          cancelarTitle(campo);          
          let dataTmp = useCase;
          if(campo == 'useCaseName'){
            dataTmp.useCaseName = valueIdInput;
          }else{
            dataTmp.description = valueIdInput;
          }
          setUseCase(dataTmp);
      }else{
          document.getElementById(`span${campo}`).innerHTML = 'Ocurrio un error';
          document.getElementById(`span${campo}`).setAttribute('class', '');
      }
    }        
  }  

  const EditarTitle = ({campo,titulo,refe}) => {
    return (
      <>
        {
          (campo=='useCaseName')
            ? <h3 className={'h3 editaz'} 
                  id={`title-${campo}`} onClick={() => editarUseCase(`${campo}`)}>
                    {titulo}
              </h3>
            : <p className={'info_title editaz'} 
                  id={`title-${campo}`} onClick={() => editarUseCase(`${campo}`)}>
                    {titulo}
              </p>
        }      
        <div className="div-cont-input oculto" id={`div-cont-input-${campo}`}>
          <input type="text" ref={refe} 
            className='oculto'                             
            onChange={() => ocultarSpan(`span${campo}`)} 
          />
          <div className='div-botns'>
            <a className="verde_add verde_boton"
              onClick={() => guardarTitles(`${campo}`, refe)}>
              Guardar
            </a>
            <a className="down_cancelar verde_add" 
              onClick={()=> cancelarTitle(`${campo}`)}
              id={`cancelar-${campo}`}>Cancelar</a>
          </div>    
          <span id={`span${campo}`} className='oculto'></span>
        </div>  
      </>
    )
  }

  if(useCase.useCaseName){
    return (
      <>
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          aria-labelledby="confirm-dialog"
        >
          <DialogTitle id="confirm-dialog">¿Esta seguro de eliminar el destinatario?</DialogTitle>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => setOpenDialog(false)}
              color="success"
            >
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setOpenDialog(false);
                confirmarBorrado();
              }}
              color="warning"
            >
              Si
            </Button>
          </DialogActions>
        </Dialog>
        <form className='formNotifi'>
          <input type="hidden" ref={valueBorrar} />
          <input type="hidden" ref={valueInsertar} />
          <input type="hidden" ref={valueNotifis} />
          <div className='blockNotificaciones'>                                                          
            <div className='blockintnot'>
              <EditarTitle campo="useCaseName" titulo={useCase.useCaseName} refe={inputUseCaseName} />                  
              <EditarTitle campo="description" titulo={useCase.description} refe={inputUseCaseDescription} />                                                                                    
              <div className='block-interno'>
                <div className="int-p">
                  <p>Envío de Email</p>
                  <Switch name="NofifiEmail"
                    checked={UsesCaseEmail} 
                    onChange={() => {
                      handleChangeEmail(); 
                      valueNotifis.current.value = `{"NotifiEmail": ${!UsesCaseEmail}, "NofifiSms": ${UsesCaseSMS}}`;
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                    />
                </div>
                <div className="add-email" id={`_add-email`}>
                  <ShowDestins 
                    uc={useCase} 
                    tipo="email" 
                    setOpenDialog={setOpenDialog} 
                    valueBorrar={valueBorrar}
                    valueInsertar={valueInsertar}
                  />
                </div> 
              </div>
              <div className='block-interno'>
                <div className="int-p">
                  <p>Envío de SMS</p>
                  <Switch name="NofifiSms"
                    checked={UsesCaseSMS} 
                    onChange={() => {
                      handleChangeSMS(); 
                      valueNotifis.current.value = `{"NotifiEmail": ${UsesCaseEmail}, "NofifiSms": ${!UsesCaseSMS}}`;
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  /> 
                </div >
                <div className="add-sms" id={`_add-sms`}>
                  <ShowDestins 
                    uc={useCase} 
                    tipo="sms" 
                    setOpenDialog={setOpenDialog} 
                    valueBorrar={valueBorrar}
                    valueInsertar={valueInsertar}
                  />
                </div>
              </div>
            </div>                    
          </div> 
        </form>
      </>
    )
  }else{
    return (
      <>
        <div className="loading">
          <CircularProgress />
        </div>       
      </>      
    )
  }

  
}

export default function Configs(){ 

  const [snackBar, setSnackBar] = useState(false);
  const [snackBarStatus, setSnackBarStatus] = useState('success');

  return (       
    <Page title="Dashboard | Centro Monitoreo IoT">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Envío de notificaciones</Typography>
        </Box>
          <Grid container spacing={3}>
            <Snackbar 
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }} 
              open={snackBar}
              autoHideDuration={2000} 
              onClose={() => {
                setSnackBar(false);
              }}
              color="success"
            >
              <Alert severity={snackBarStatus} sx={{ width: '100%' }}>
              {
                snackBarStatus === 'success' ? 'Cambios guardados' : 'Intentalo nuevamente'
              }
              </Alert>                
            </Snackbar>
            <FormularioInterno 
              setSnackBar={setSnackBar} 
              setSnackBarStatus={setSnackBarStatus}
            /> 
          </Grid>
      </Container>
    </Page>
  );
    
}  
  