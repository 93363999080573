import axios from 'axios';
import { baseUrl } from '../constants';
import { getToken } from '../tokens';

export async function getReportStats(query){
    const token = getToken();
    return axios.get(
      `${baseUrl}/reports/stats?${query}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
}

export async function createReportStats(query){
  const token = getToken();
  return axios.post(
    `${baseUrl}/reports/stats`,
    query,
    {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
  );
}