import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'gráficos',
    path: '/dashboard/all-graphics',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'estadísticas',
    path: '/dashboard/stats',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'reportes',
    icon: getIcon(pieChart2Fill),
    children: [
      {
        title: "estadísticas",
        path: '/dashboard/reportes/estadisticas'
      }
    ]
  },
  {
    title: 'notificaciones',
    icon: getIcon(pieChart2Fill),
    children: [
      {
        title: "reporte",
        path: '/dashboard/notificaciones'
      },
      {
        title: "mensajería",
        path: '/dashboard/configuracion'
      }
    ]
  },
  {
    title: 'Cambiar Contraseña',
    path: '/dashboard/change-password',
    icon: getIcon(pieChart2Fill)
  },
];

export default sidebarConfig;
