import { getCognitoUser, refreshCognitoToken } from 'src/services/auth.service';
import { getToken, setAccessToken, setToken, setRefreshToken, getRefreshToken } from 'src/services/tokens';
import * as moment from 'moment';
import axios from 'axios';
import { baseUrl } from '../../services/constants';
import { isNull } from 'lodash';
moment.locale('es');

export const ocultarSpan = (id) => {
    document.getElementById(id).innerText = "";
    document.getElementById(id).setAttribute('class','error_span');
}
  
const validar_destinatario = (e,valueInsertar) => {
    const id_dom = e.target.id;
    const porciones = id_dom.split('_');
    const tipo = porciones[0];
  
    const value_nombre = document.getElementsByName(`${id_dom}-name`);
    const value_phoneemail = document.getElementsByName(`${id_dom}-phoneemail`);
  
    const phone_email = value_phoneemail[0].value; 
  
    let sw_nombre = false, sw_phoneemail = false;
  
    const fn_error_span = (txt,tipo_span) => {
      document.getElementById(`${id_dom}_${tipo_span}_span`).innerText = txt;
      document.getElementById(`${id_dom}_${tipo_span}_span`).setAttribute('class','error_span active');
    }
  
    if(value_nombre[0].value){
      sw_nombre = true;
    }else{
      fn_error_span("Nombre obligatorio","nombre")
    }
  
    if(phone_email){
      if(tipo == 'sms'){
        if(phone_email >= 900000000 && phone_email <= 999999999){
          sw_phoneemail = true;
        }else{
          fn_error_span("Teléfono no valido","phoneemail");
        }      
      }else{  
        const emailRegex = /\S+@\S+\.\S+/;
  
        if(emailRegex.test(phone_email)){
          sw_phoneemail = true;
        }else{
          fn_error_span("Formato incorrecto","phoneemail");
        }
      }
    }else{
      fn_error_span(
        (tipo == 'sms') 
          ? 'Teléfono Obligatorio' 
          : 'Correo Obligatorio', 
        "phoneemail"
      );
    }
  
    if(sw_nombre && sw_phoneemail){  
  
      document.getElementById(`_add-${tipo}`).setAttribute("class","add-sms cargando");
  
      if(tipo == 'sms'){
        valueInsertar.current.value = `{"tipo": "${tipo}", "name": "${value_nombre[0].value}", "phone": ${phone_email}}`;      
      }else{
        valueInsertar.current.value = `{"tipo": "${tipo}", "name": "${value_nombre[0].value}", "email": "${phone_email}"}`;
      }
  
      //document.getElementsByName(`${id_dom}-insertar_nombre`).value = value_nombre[0].value;
      //document.getElementsByName(`${id_dom}-insertar_phoneemail`).value = phone_email;
  
      value_nombre[0].value = "";
      value_phoneemail[0].value = "";
         
    }
  
}
  
const switcherDomDesti = (tipo) => {
    const id_dom_camp_db = document.getElementById(`${tipo}-campo_db`);  
  
    if(id_dom_camp_db.classList.contains("hidden")){
      id_dom_camp_db.setAttribute('class','campo_db');
  
      document.getElementById(`${tipo}`).innerText = "Cancelar";
      document.getElementById(`${tipo}`).setAttribute('class','down_cancelar verde_add');
    }else{  
      id_dom_camp_db.setAttribute('class','campo_db hidden')
  
      document.getElementById(`${tipo}`).innerHTML = (tipo == 'sms') ? 'Añadir teléfono' : 'Añadir correo';
      document.getElementById(`${tipo}`).setAttribute('class','verde_add');
    }
}
  
const AddbloqueDesti = ({tipo,valueInsertar}) => {
  
    //let porciones = id_dom.split('_');
    //const tipo = porciones[0];
    const label_phoneemail = (tipo == 'sms') ? 'Teléfono:' : 'Correo:';
  
    return (
      <>
        <div className="campo_db hidden" id={`${tipo}-campo_db`}>
          <div className="int_name">
            <label>Nombre:</label>
            <p className="p_input_nombre" >
              <input 
                type="text" 
                name={`${tipo}_input-name`}
                onChange={(e) => ocultarSpan(`${tipo}_input_nombre_span`, e)} 
              />              
              <span 
                id={`${tipo}_input_nombre_span`} 
                className="error_span"></span>
            </p>
          </div>
          <div className="int_phoneemail">
            <label>{label_phoneemail}</label>
            <p className="p_input_phoneemail">
              <input 
                type="text" 
                name={`${tipo}_input-phoneemail`}
                onChange={(e) => ocultarSpan(`${tipo}_input_phoneemail_span`, e)} 
              />               
              <span 
                id={`${tipo}_input_phoneemail_span`} 
                className="error_span"></span>
            </p>
          </div>
          <a className="verde_add verde_boton" id={`${tipo}_input`} onClick={(e) => validar_destinatario(e,valueInsertar)}>
            Guardar
          </a>
        </div>
      </>
    )
}
  
export const ShowDestins = ({uc,tipo,setOpenDialog,valueBorrar,valueInsertar}) => {
    
    const destinationsTipo  = (tipo=='sms') ? uc.destinationsSms : uc.destinationsEmail;  
    const texto_add = (tipo == 'sms') ? 'Añadir teléfono' : 'Añadir correo';
  
    const borrar = (index, tipo) => {
      setOpenDialog(true);
      valueBorrar.current.value = `{"posicion": ${index}, "tipo": "${tipo}"}`    
    }
  
    if(typeof destinationsTipo !== 'object'){    
      return (
        <>
          <input type="hidden" name={`${tipo}_-count-dests`} value="0" key={`ShowDestins_1`}/>
          <div id={`${tipo}_-adds`} key={`ShowDestins_2`}>          
            <div id={`${tipo}_-adds_int`} key={`ShowDestins_3`}>
              <AddbloqueDesti tipo={tipo} key={`ShowDestins_4`} valueInsertar={valueInsertar} />
            </div>
          </div>
          <a className="verde_add" id={tipo} onClick={() => switcherDomDesti(tipo)} key={`ShowDestins_5`}>{texto_add}</a>        
        </>      
      )
    }else{       
      return (
        <>
          <input type="hidden" name={`${tipo}_-count-dests`} value={destinationsTipo.length} key={`ShowDestins__1`}/>
          {
            destinationsTipo.map((destination,i) => (
              <>
                <div className={`campo_db ${tipo}_`} key={`ShowDestins_${i}_2`}>
                  <div className="int_name" key={`ShowDestins_${i}_3`}>
                    <label key={`ShowDestins_${i}_4`}>Nombre:</label>
                    <p key={`ShowDestins_${i}_5`}>{destination.name}</p>
                    <input type="hidden" value={destination.name} name={`${tipo}__db-name-${i}`} key={`ShowDestins_${i}_6`} />
                  </div>
                  <div className='int-phone' key={`ShowDestins_${i}_7`}>
                    <label key={`ShowDestins_${i}_8`}>{(tipo == 'sms') ? 'Teléfono:' : 'Correo:'}</label>
                    <p key={`ShowDestins_${i}_9`}>{(tipo == 'sms') ? destination.phone : destination.email}</p>
                    <input key={`ShowDestins_${i}_10`} type="hidden" value={(tipo == 'sms') ? destination.phone : destination.email} name={`${tipo}__db-phoneemail-${i}`} />
                  </div> 
                  <input type="hidden" id={`${tipo}_-borrar#${i}#_input`} key={`ShowDestins_${i}_11`}/>                
                  <a className='delcampo' id={`${tipo}_-borrar#${i}#`} onClick={() => borrar(i,tipo)} key={`ShowDestins_${i}_12`}>Borrar</a>                              
                </div>                             
              </>
            ))         
          } 
          <div id={`${tipo}_-adds`}>
            <div id={`${tipo}_-adds_int`}>
              <AddbloqueDesti tipo={tipo} valueInsertar={valueInsertar} />
            </div>
          </div>
          <a className="verde_add" id={tipo} onClick={() => switcherDomDesti(tipo)} >{texto_add}</a>        
        </>     
      )    
    }
    
}

export function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  
    return JSON.parse(jsonPayload);
}
  
export function checkTokenExp(token, refreshToken) {
    let sessionObs = setInterval(async () => {
      let tokenDecoded = parseJwt(token);
      let time_exp = tokenDecoded.exp * 1000;
      let today = moment().valueOf();
  
      if(today >= time_exp) {
        console.info('El token ha expirado. ('+ token +')');
        let cognitoUser = getCognitoUser(tokenDecoded['cognito:username']);
        let response = await refreshCognitoToken(cognitoUser, refreshToken);
        console.log('Los nuevos tokens son::')
        console.log(response)
  
        setToken(response.accessToken);
        setAccessToken(response.idToken);
        setRefreshToken(response.refreshToken)
  
        clearInterval(sessionObs);
        checkTokenExp(getToken(), refreshToken);
  
        //localStorage.clear();
        //navigate('/login', { replace: true });
      }
    }, 5000);
}

export const getUseCase = async (groupId) => { 
    let token = getToken();
    let refreshToken = getRefreshToken();

    if(!isNull(token)) {       
      checkTokenExp(token, refreshToken);      
    }

    return await axios.get(
      `${baseUrl}/use-cases/${groupId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    ); 
}  
  
export const createUseCase = async (groupId) => {
    let token = getToken();
    let refreshToken = getRefreshToken();

    if(!isNull(token)) {       
      checkTokenExp(token, refreshToken);      
    }

    return await axios.post(
      `${baseUrl}/use-cases`,
      {
        description: `Fábrica ${groupId}`,
        useCaseName: `Fábrica ${groupId}`,
        useCaseId: `${groupId}`
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    ); 
}

export const updateUseCase = async (body, groupId, setSnackBar, setSnackBarStatus) => {
    let token = getToken();
    let refreshToken = getRefreshToken();

    if(!isNull(token)) {       
      checkTokenExp(token, refreshToken);      
    }

    const putdata = await axios.put(
      `${baseUrl}/use-cases/${groupId}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    setSnackBar(true);

    if(putdata.data.status == 'ok'){      
      setSnackBarStatus('success');       
    }else{
      setSnackBarStatus('error');
    }

    return putdata;
}  

export const cancelarTitle = (campo) => { 
    const classEditaz = (campo == 'useCaseName')
        ? 'h3 editaz'
        : 'info_title editaz';  
    document.getElementById(`title-${campo}`).setAttribute('class', classEditaz);
    document.getElementById(`div-cont-input-${campo}`).setAttribute('class', 'div-cont-input oculto');    
    document.getElementById(`span${campo}`).innerHTML = "";
}
