import { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router';
import { parse as QueryParse, stringify } from 'query-string';
import ReactApexChart from 'react-apexcharts';
import { 
    Box,
    Typography,
    Stack,
    Backdrop,
    CircularProgress,
    alpha,
    Select,
    MenuItem,
    Paper,
    Button
} from '@material-ui/core';
import { getKw } from '../services/kw.service';
import moment from 'moment';

export default function KwhPage(){
    const location = useLocation();
    const [year, setYear] = useState(moment().year());
    const [month, setMonth] = useState(null);
    const [day, setDay] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [usage, setUsage] = useState(0);
    const {
        type,
        device
    } = useMemo(() => location.state, []);

    const intervalYears = useMemo(() => {
        const yy = [];
        for(let i = moment().year(); i >= 2021; i--){
            yy.push(i);
        }
        return yy;
    }, []);

    const listMonths = useMemo(() => {
        return moment.months();
    }, [year]);

    const listDays = useMemo(() => {
        const nn = moment(`${year}-${month?.toString().padStart(2, '0')}`, 'YYYY-MM').daysInMonth();
        const dd = [...Array(nn).keys()];
        return dd;
    }, [month]);

    useEffect(async () => {
        setLoading(true);
        const devices = device.device.deviceId;
        let action = 'by_year';
        let date = year;
        if(year && month && day){
            action = 'by_day';
            date = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
            const dd = [...Array(23).keys()]
            const qq = stringify({
                action,
                date,
                device: devices
            });
            const {payload: {totalUsage = 0, data = []}} = await getKw(qq);
            const toDraw = dd.map((element) => {
                const selectedDate = moment(`${date} ${(element+1).toString().padStart(2, '0')}`, 'YYYY-MM-DD HH');
                const r = data.find((element) => {
                    return selectedDate.format('YYYY-MM-DD HH') === element.date
                });
                const usage = r ? (+r.usage).toFixed(2) : 0;
                return {
                    x: selectedDate.format('HH'),
                    y: usage,
                    info: `${selectedDate.format('[A las] HH [horas]')}: ${usage} kwh`
                }
            });
            setUsage(totalUsage ?? 0);
            setData([{
                data: toDraw,
                name: "Horas",
            }]);
        }else if(year && month){
            action = 'by_month';
            date = `${year}-${month.toString().padStart(2, '0')}`;
            const nn = moment(date, 'YYYY-MM').daysInMonth();
            const dd = [...Array(nn).keys()]
            const qq = stringify({
                action,
                date,
                device: devices
            });
            const {payload: {totalUsage = 0, data = []}} = await getKw(qq);
            const toDraw = dd.map((element) => {
                const selectedDate = moment(`${date}-${(element+1).toString().padStart(2, '0')}`, 'YYYY-MM-DD');
                const r = data.find((element) => {
                    return selectedDate.format('YYYY-MM-DD') === element.date
                });
                const usage = r ? (+r.usage).toFixed(1) : 0;
                return {
                    x: selectedDate.format('DD'),
                    y: usage,
                    info: `${selectedDate.format('ddd')} ${selectedDate.format('DD')}: ${usage} kwh`
                }
            });
            setUsage(totalUsage ?? 0);
            setData([{
                data: toDraw,
                name: "Días",
            }]);
        }else if(year){
            action = 'by_year';
            date = year;
            const dd = [...Array(12).keys()]
            const qq = stringify({
                action,
                date,
                device: devices
            });
            const {payload: {totalUsage = 0, data = []}} = await getKw(qq);
            const toDraw = dd.map((element) => {
                const date = moment(`${year}-${(element+1).toString().padStart(2, '0')}`, 'YYYY-MM');
                const r = data.find((element) => {
                    return date.format('YYYY-MM') === element.date
                });
                const usage = r ? (+r.usage).toFixed(1) : 0;
                return {
                    x: date.format('MMMM'),
                    y: usage,
                    info: `${date.format('MMMM')}: ${usage} kwh`
                }
            });
            setUsage(totalUsage ?? 0);
            setData([{
                data: toDraw,
                name: "Meses",
            }]);
        }
        setLoading(false);
    }, [year, month, day]);

    return (
        <>
            <Box sx={{width: '100%', px: 2}}>
                <Stack spacing={4}>
                    <Stack spacing={1}>
                        <Box sx={{display: 'flex', flexFlow: 'row', justifyContent: 'space-between'}}>
                            <Typography variant="h4">Historial de Consumo KWH</Typography>
                        </Box>
                        <Stack spacing={1}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography variant="subtitle2">C&oacute;digo:</Typography>
                                <Typography variant="caption">{device.device.deviceId}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography variant="subtitle2">Descripci&oacute;n:</Typography>
                                <Typography variant="caption">{device.device.descripcion}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography variant="subtitle2">Grupo:</Typography>
                                <Typography variant="caption">{device.device.grupo}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography variant="subtitle2">Unidad:</Typography>
                                <Typography variant="caption">{device.device.unidad_medida}</Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start'
                    }}>
                        <Stack spacing={2}>
                            <Stack direction="row" spacing={3}>
                                <Stack spacing={1}>
                                    <Typography variant="subtitle2">Año</Typography>
                                    <Select value={year} onChange={(event) => setYear(event.target.value)} sx={{
                                        '.MuiSelect-select': (theme) => ({
                                            padding: `${theme.spacing(1.3)} ${theme.spacing(1)}`
                                        })
                                    }}>
                                        {
                                            intervalYears.map((element) => {
                                                return <MenuItem value={element}>{element}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </Stack>
                                <Stack spacing={1}>
                                    <Typography variant="subtitle2">Mes</Typography>
                                    <Select value={month} onChange={(event) => setMonth(event.target.value)} sx={{
                                        '.MuiSelect-select': (theme) => ({
                                            padding: `${theme.spacing(1.3)} ${theme.spacing(1)}`
                                        })
                                    }}>
                                        {
                                            listMonths.map((element, index) => {
                                                return <MenuItem value={index + 1}>{element}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </Stack>
                                <Stack spacing={1}>
                                    <Typography variant="subtitle2">Día</Typography>
                                    <Select value={day} onChange={(event) => setDay(event.target.value)} sx={{
                                        '.MuiSelect-select': (theme) => ({
                                            padding: `${theme.spacing(1.3)} ${theme.spacing(1)}`
                                        })
                                    }}>
                                        {
                                            listDays.map((element) => {
                                                return <MenuItem value={element+1}>{(element+1).toString().padStart(2, '0')}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </Stack>
                                <Stack spacing={1}>
                                    <Typography variant="subtitle2">&nbsp;</Typography>
                                    <Box sx={{
                                        flex: 1
                                    }}>
                                        <Box sx={{
                                            display: 'inline-flex',
                                            height: '100%'
                                        }}>
                                            <Button variant="contained" onClick={() => {
                                                setMonth(null);
                                                setDay(null);
                                            }}>Limpiar</Button>
                                        </Box>
                                    </Box>
                                </Stack>
                        
                            </Stack>
                        </Stack>
                    </Box>
                    <Box
                        sx={{
                            p: 1,
                            m: 1,
                            width: '100%',
                            bgcolor: 'background.paper',
                            boxSizing: 'border-box',
                            position: 'relative'
                        }}
                    >
                        <Backdrop
                            sx={{ 
                                background: alpha('#FFF', 0.85), 
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                                position: 'absolute',
                            }}
                            open={loading}
                            >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        <Paper elevation={3} sx={{
                            boxSizing: 'border-box',
                            p: 3
                        }}>
                            <Stack spacing={2}>
                                <Stack spacing={.5}>
                                    <Stack direction="row" spacing={1}>
                                        <Typography variant="subtitle1">Consumo total: </Typography>
                                        <Typography variant="subtitle1">{usage.toFixed(2)}</Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <Typography variant="body2">Fecha: </Typography>
                                        {
                                            year && !month ? (
                                                <Typography variant="body2">{year}</Typography>
                                            ) : null
                                        }
                                        {
                                            year && month && !day ? (
                                                <Typography variant="body2">{year}-{month.toString().padStart(2, '0')}</Typography>
                                            ) : null
                                        }
                                        {
                                            year && month && day ? (
                                                <Typography variant="body2">{year}-{month.toString().padStart(2, '0')}-{day.toString().padStart(2, '0')}</Typography>
                                            ) : null
                                        }
                                    </Stack>
                                </Stack>
                                <ReactApexChart type="bar" labels={['horas']} series={data} options={{
                                    chart: {
                                        toolbar: {
                                            show: false
                                        },
                                        zoom: {
                                            enabled: true,
                                            type: 'x',
                                            resetIcon: {
                                                offsetX: -10,
                                                offsetY: 0,
                                                fillColor: '#fff',
                                                strokeColor: '#37474F'
                                            },
                                            selection: {
                                                background: '#90CAF9',
                                                border: '#0D47A1'
                                            }    
                                        },
            
                                    },
                                    legend: {
                                        showForSingleSeries: true,
                                        markers: {
                                            fillColors: ['transparent']
                                        },
                                        onItemClick: {
                                            toggleDataSeries: false
                                        },
                                        onItemHover: {
                                            highlightDataSeries: false
                                        },
                                    },
                                    dataLabels: {
                                        enabled: true,
                                        enabledOnSeries: false,
                                        style: {
                                            fontSize: '12px',
                                            fontFamily: 'Helvetica, Arial, sans-serif',
                                            fontWeight: 'bold',
                                            colors: ['#394C5A']
                                        },
                                        background: {
                                            enabled: true,
                                            foreColor: '#fff',
                                            padding: 4,
                                            borderRadius: 2,
                                            borderWidth: 0,
                                            borderColor: '#fff',
                                            opacity: 1,
                                            dropShadow: {
                                              enabled: false,
                                              top: 1,
                                              left: 1,
                                              blur: 1,
                                              color: '#000',
                                              opacity: 0.45
                                            }
                                          },
                                    },
                                    plotOptions: {
                                        bar: {
                                          dataLabels: {
                                            orientation: 'horizontal',
                                            position: 'top',
                                          }
                                        }
                                    },
                                    tooltip: {
                                        x: {
                                            show: false,
                                        },
                                        y: {
                                            formatter: function(value, {series, seriesIndex, dataPointIndex, w}) {
                                                var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                                                return data.info;
                                            },
                                            title: {
                                                formatter: function (seriesName) {
                                                  return ''
                                                }
                                            }
                                        },
                                        marker: {
                                            show: false,
                                        },
                                    },
                                    subtitle: {
                                        text: 'Consumo Kwh',
                                        align: 'left',
                                        margin: 10,
                                        offsetX: 0,
                                        offsetY: 0,
                                        floating: false,
                                        style: {
                                            fontSize:  '12px',
                                            fontWeight:  'normal',
                                            fontFamily:  undefined,
                                            color:  '#9699a2'
                                        },
                                    },
                                }} width={'100%'} height={400} />
                            </Stack>
                        </Paper>
                    </Box>
                </Stack>
            </Box>
        </>
    );
}