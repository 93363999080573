import axios from 'axios';
import { baseUrl } from './constants';
import * as moment from 'moment-timezone';
import 'moment/locale/es';

export async function getDeviceData(token, ts, interval, devices) {

  moment.locale('es'); 

  const header = { headers: { Authorization: `Bearer ${token}`}};
     
  if(ts === ""){
    const { data } = await axios.post(
      `${baseUrl}/devices-data`,
      { ts, interval, devices: [ devices ] },
      header
    );
    return data;
  }     
  
  const porciones = devices.split('#');
  const fabrica = porciones[3];
  const imei = Number(porciones[0]);
  const valorA = Number(porciones[1]);
  const st = porciones[2];

  const bodyPayload = {
    "fabrica": fabrica,
    "imei": imei,
    "a": valorA,
    "st": st,
    "fechaInicio": `${ts} 00:00:00`,
    "fechaFin": `${ts} 23:59:59`,
    "agrupacion": 1,
    "unidad_agrupacion": "minutos",
    "pageSize": 2000
  };

  let pagingFin = 1;
  let datainterna = [];

  const {data} = await axios.post(
    `${baseUrl}/device/historical-data/${pagingFin}`, 
    bodyPayload, 
    header
  ); 
  const {payload} = data;
  const {generalData} = payload;

  generalData.dataHistorica.map((dataHis) => {
    datainterna = [...datainterna, {
      a: valorA,
      deviceId: devices,
      fabrica: fabrica,
      imei: imei,
      st: st,
      ts: dataHis.fecha,
      v1: dataHis.promedio            
    }]
  });
  

  while(data.paging.total > 1){
    pagingFin++;

    const {payload} = await axios.post(`${baseUrl}/device/historical-data/${pagingFin}`, bodyPayload, header);
    const {generalData} = payload;

    generalData.dataHistorica.map((dataHis) => {
      datainterna = [...datainterna, {
        a: valorA,
        deviceId: devices,
        fabrica: fabrica,
        imei: imei,
        st: st,
        ts: dataHis.fecha,
        v1: dataHis.promedio            
      }]
    })
  }  

  return {
    data: [
      {
        data: datainterna,
        deviceId: devices,
        ts: `${ts} 00:00:00`
      }
    ]
  };      
      
}

export async function getStatsData(token){
    return await axios.get(
      `${baseUrl}/dashboard/stats`,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    );
}
  
export async function getAlertsData(token, today, lastTime, isLoaded, devices) {
  const header = { headers: { Authorization: `Bearer ${token}` } };
  const payload = { today, lastTime, isLoaded, devices };

  const { data } = await axios.post(`${baseUrl}/alerts`, payload, header);

  return data;
}